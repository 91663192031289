import styles from "./TermsItem.module.scss";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";

export interface TermsAndConditions {
    id: string;
    question: string;
    answer: string[];
}

export interface TermsProps {
    index: number;
    item: TermsAndConditions;
}

const TermsItem = ({ index, item }: TermsProps) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={styles.root}>
            <div className={styles.contentContainer}>
                <div className={styles.questionAndAnswerContainer}>
                    <div
                        className={styles.questionContainer}
                    >
                        <Typography className={styles.questionText}>{`${item.question}`}</Typography>
                    </div>

                    {
                         item.answer.map((answer) =>
                              (
                                  <Typography
                                      className={styles.answerText}
                                      dangerouslySetInnerHTML={{
                                          __html: answer,
                                      }}
                                  />
                              ),
                          )
                    }
                </div>
            </div>
        </div>
    );
};

export default TermsItem;
