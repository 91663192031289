import styles from './FAQ.module.scss';
import FAQImage1 from '../../../assets/faqImage1.jpg';
import FAQImage1Mobile from '../../../assets/faqImage1Mobile.jpg';
import Footer from '../../footer/Footer';
import Typography from '@mui/material/Typography';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import FAQItem from './FAQItem';
import { buyerFaq, sellerFaq } from './FAQData';
import { useMediaQuery } from 'react-responsive';
import { MobileScreenWidth } from '../../../../constants';
import { useKeyboardDebounce } from '../../../hooks/useKeyboardDebounce';

const FAQ = () => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });
        
    const [isBuyer, setIsBuyer] = useState(true);
    const [currentFaq, setCurrentFaq] = useState(buyerFaq);
    const [filteredFaq, setFilteredFaq] = useState(buyerFaq);
    const [searchValue, setSearchValue] = useState('');

    const performSearch = useCallback((searchText: string) => {
        const searchStr = searchText.trim().toLowerCase();

        const results = !searchStr ? currentFaq : currentFaq.filter(x => {
            if (x.question.toLowerCase().includes(searchStr)) {
                return true;
            }

            for (let i = 0; i < x.answer.length; i++) {
                if (x.answer[i].toLowerCase().includes(searchStr)) {
                    return true;
                }
            }

            return false;
        });

        setFilteredFaq(results);
    }, [currentFaq]);

    const debouncedSeachValueChanged = useKeyboardDebounce(performSearch);

    const setSearchValueDirect = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    }, []);

    useEffect(() => {
        debouncedSeachValueChanged(searchValue);
    }, [currentFaq, debouncedSeachValueChanged, searchValue]);

    return (
        <div className={styles.root}>
            <div className={styles.imageContainer1}>
                <img className={styles.faqImage} src={isDesktop ? FAQImage1 : FAQImage1Mobile} alt="FAQ" />
            </div>

            <div className={styles.titleContainer}>
                <Typography className={styles.titleText}>Frequently asked questions</Typography>
            </div>

            {isDesktop ? (
                <div className={styles.searchContainer}>
                    <Typography className={styles.haveQuestionsText}>Have some questions? We're here to help.</Typography>

                    <div className={styles.searchInputContainer}>
                        <TextField
                            className={styles.emailInput}
                            fullWidth
                            id="searchInput"
                            label="Search"
                            InputLabelProps={{
                                style: {
                                    color: '#1D3053C7',
                                    fontSize: '14px',
                                    fontWeight: '400'
                                }
                            }}
                            variant="standard"
                            value={searchValue}
                            onChange={setSearchValueDirect}
                        />
                    </div>
                </div>
            ) : (
                <div className={styles.searchContainerMobile}>
                    <Typography className={styles.haveQuestionsText}>Have some questions? We're here to help.</Typography>

                    <div className={styles.searchInputContainer}>
                        <TextField
                            className={styles.searchInput}
                            fullWidth
                            id="searchInput"
                            label="Search"
                            InputLabelProps={{
                                style: {
                                    color: '#1D3053C7',
                                    fontSize: '14px',
                                    fontWeight: '400'
                                }
                            }}
                            variant="standard"
                            onChange={setSearchValueDirect}
                        />
                    </div>
                </div>
            )}

            <div className={styles.buyerSellerContainer}>
                <Typography className={styles.buyerSellerText}>I am a...</Typography>

                {isBuyer ? (
                    <div className={styles.buyerContainer}>
                        <div className={styles.buyerButton}>
                            <Typography className={styles.buyerText}>Buyer</Typography>
                        </div>
                        <div className={styles.sellerButton} onClick={() => {
                            setIsBuyer(false);
                            setCurrentFaq(sellerFaq);
                        }}>
                            <Typography className={styles.sellerText}>Seller</Typography>
                        </div>
                    </div>
                ) : (
                    <div className={styles.sellerContainer}>
                        <div className={styles.buyerButton} onClick={() => {
                            setIsBuyer(true);
                            setCurrentFaq(buyerFaq);
                        }}>
                            <Typography className={styles.buyerText}>Buyer</Typography>
                        </div>
                        <div className={styles.sellerButton}>
                            <Typography className={styles.sellerText}>Seller</Typography>
                        </div>
                    </div>
                )}
            </div>

            {isDesktop ? (
                <div className={styles.faqContainer}>
                    {filteredFaq.map((item, index) => 
                        <FAQItem key={item.id} item={item} index={index} />
                    )}
                </div>
            ) : (
                <div className={styles.faqContainerMobile}>
                    <div className={styles.faqItems}>
                        {filteredFaq.map((item, index) => 
                            <FAQItem key={item.id} item={item} index={index} />
                        )}
                    </div>
                </div>
            )}

            <Footer hideInfoLinks={false} />
        </div>
    );
}

export default FAQ;