import { useState, useContext, useCallback, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
    getProducts,
    generateNewProduct,
    createAttachment,
    getBatchDirectUploadUrl,
} from "../../../../services/auction-service";
import { useFetchWrapper } from "../../../../hooks/useFetchWrapper";
import { loggingService } from "../../../../../utils/logging/logging";
import { AuthContext } from "../../../../providers/AuthProvider";
import { ProductGroups } from "../../../../models/ProductInfo";
import { SnackContext } from "../../../../providers/SnackProvider";
import AuctionSellImage1 from "../../../../assets/auctionSellImage1.jpg";
import AuctionSellImage1Mobile from "../../../../assets/auctionSellImage1Mobile.jpg";
import TrustPilot from "../../../../assets/trustpilot.jpg";
import Footer from "../../../footer/Footer";
import FileUploadComponent from "../../../fileupload/FileUploadComponent";
import { FileUploadInfo } from "../../../fileupload/FileUploadItem";
import { MobileScreenWidth } from "../../../../../constants";
import BackDrop from "../../../backDrop/BackDrop";
import styles from "./AuctionSell.module.scss";
import WatchDetails from "./WatchDetails";
import { ItemCreateRequest } from "@hmxlabs/dax-client/build/services/generated/dax-api/data-contracts";

const { log } = loggingService("app:AuthSell");

const alertMsg = {
    addWatch: {
        success: "Your watch was successfully added and is under review",
        failed: "Apologies, but your watch failed to be added ...try again.",
    },
    addAtachments: {
        failed: "Apologies, but your attachments failed to be added ...try again.",
    },
};

const AuctionSell = () => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });

    const showSnackbar = useContext(SnackContext);
    const [isLoading, setIsLoading] = useState(false);
    const { postWithToken } = useFetchWrapper();
    const navigate = useNavigate();
    const { user, login } = useContext(AuthContext);
    const [sellInfo, setSellInfo] = useState({
        hasModelNumber: false,
        hasYear: false,
        hasBox: false,
        hasPapers: false,
        year: "",
        modelNumber: "",
    });
    const [watchModel, setWatchModel] = useState("");
    const [watchBrand, setWatchBrand] = useState("");
    const [modelNumber, setModelNumber] = useState("");
    const [brands, setBrands] = useState<any[]>([]);
    const [models, setModels] = useState<any[]>([]);
    const [variants, setVariants] = useState<any[]>([]);
    const [allProducts, setAllProducts] = useState<ProductGroups>({});
    const [files, setFiles] = useState<FileUploadInfo[]>([]);

    const loadProducts = useCallback(async () => {
        try {
            log("getting watch summary");
            const productsInfo = await getProducts();
            const data = Object.keys(productsInfo) as (keyof ProductGroups)[];
            setAllProducts(productsInfo);
            setBrands(data);
        } catch (error) {
            showSnackbar(
                "An error occurred loading the brand listings. Please try again later.",
                { alertSeverity: "error" },
            );
            setBrands([]);
        }
    }, [showSnackbar]);

    useEffect(() => {
        loadProducts();
    }, [loadProducts]);

    const uploadImages = useCallback(
        async (item: any) => {
            try {
                const { url, token } = await getBatchDirectUploadUrl();
                if (!url || !token) {
                    log("No upload URL, Token");
                    throw new Error(alertMsg.addAtachments.failed);
                }
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    const formData: any = new FormData();
                    formData.append("file", file.file);
                    const upload = await postWithToken(
                        url,
                        token,
                        formData,
                        true,
                    );
                    const res = await upload.json();
                    if (res.success) {
                        await createAttachment(item.id, res.result);
                    } else {
                        throw new Error(alertMsg.addAtachments.failed);
                    }
                }
            } catch (error: any) {
                throw new Error(error.message);
            }
        },
        [files, postWithToken],
    );

    const handleChangeBrand = useCallback(
        (value: any) => {
            if (!value) {
                setWatchBrand("");
                setWatchModel("");
                setModelNumber("");
                setModels([]);
                setVariants([]);
            } else {
                setWatchBrand(value);
                setWatchModel("");
                setModelNumber("");
                const modelsInfo = [
                    ...new Set(
                        allProducts[value].map((item) => item.model),
                    ),
                ];
                setModels(modelsInfo);
            }
        },
        [allProducts],
    );

    const handleChangeModel = useCallback(
        (value: any) => {
            setWatchModel(value);
            const allVariants = allProducts[watchBrand]
                .filter((item) => item.model === value)
                .map((item) => item.variant);
            setVariants(allVariants);
        },
        [allProducts, watchBrand],
    );

    const handleLoginClick = useCallback(() => {
        login(!isDesktop);
    }, [isDesktop, login]);

    const handleFaqClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        navigate("/faq");
    };

    const generateProduct = useCallback(async () => {
        try {
            const products = allProducts[watchBrand];

            let product = products.find(
                (item) =>
                    item.model === watchModel && item.variant === modelNumber,
            );

            if (!product) {
                product = products.find((item) => item.model === watchModel);
            }
            const item: ItemCreateRequest = {
                status: 1,
                productId: product?.id,
                sellerId: user?.user_id,
                description: "",
                dateOfManufacture: "1970-01-01", // we don't use this field so just hard code it to a specific value
                condition: "",
                conditionText: "",
                properties: [
                    { name: "modelNumber", value: modelNumber },
                    { name: "year", value: sellInfo.year },
                    {
                        name: "box",
                        value: sellInfo.hasBox ? "Present" : "Missing",
                    },
                    {
                        name: "papers",
                        value: sellInfo.hasPapers ? "Present" : "Missing",
                    },
                ],
            };
            const itemResponse = await generateNewProduct(item);
            if (!itemResponse?.id) {
                throw new Error(alertMsg.addWatch.failed);
            } else return itemResponse;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }, [user, watchBrand, watchModel, allProducts, sellInfo, modelNumber]);

    const handleSubmit = useCallback(async () => {
        if (!watchBrand) {
            return showSnackbar("Please enter watch brand", {
                alertSeverity: "error",
            });
        }
        if (!watchModel) {
            return showSnackbar("Please enter watch model", {
                alertSeverity: "error",
            });
        }
        try {
            setIsLoading(true);
            const itemInfo = await generateProduct();
            if (files?.length) {
                await uploadImages(itemInfo);
            }
            setSellInfo({
                hasModelNumber: false,
                hasYear: false,
                hasBox: false,
                hasPapers: false,
                year: "",
                modelNumber: "",
            });
            setWatchModel("");
            setWatchBrand("");
            setFiles([]);
            setIsLoading(false);
            return showSnackbar(alertMsg.addWatch.success, {
                alertSeverity: "success",
            });
        } catch (error) {
            let errorMessage =
                "An error occurred sending the watch details. Please try again or contact us if the problem persists.";
            if (error instanceof Error) {
                errorMessage = error.message;
            }
            setIsLoading(false);
            return showSnackbar(errorMessage, { alertSeverity: "error" });
        }
    }, [
        showSnackbar,
        files,
        watchBrand,
        watchModel,
        generateProduct,
        uploadImages,
    ]);

    return (
        <div className={styles.root}>
            <div className={styles.contentContainer}>
                <div className={styles.imageContainer1}>
                    <img
                        className={styles.auctionSellImage}
                        src={
                            isDesktop
                                ? AuctionSellImage1
                                : AuctionSellImage1Mobile
                        }
                        alt="Auction Sell"
                    />
                </div>

                <div
                    className={
                        isDesktop
                            ? styles.titleContainer
                            : styles.titleContainerMobile
                    }
                >
                    <div
                        style={{ gridColumn: 1 }}
                        className={styles.leftTitleContainer}
                    >
                        <Typography className={styles.titleText}>
                            Quick, Easy and Free Valuations
                        </Typography>
                    </div>
                    <div
                        style={{ gridColumn: 2 }}
                        className={styles.rightTitleContainer}
                    >
                        <img
                            className={styles.trustPilotImage}
                            src={TrustPilot}
                            alt="TrustPilot"
                        />
                    </div>
                </div>

                <div
                    className={
                        isDesktop
                            ? styles.infoContainer
                            : styles.infoContainerMobile
                    }
                >
                    <div
                        style={{ gridColumn: 1 }}
                        className={styles.leftInfoContainer}
                    >
                        <Typography className={styles.infoText}>
                            Send in the details of your watch and we will give
                            you an estimated auction valuation. If you are happy
                            with our valuation, we can arrange photography and
                            curate your listing ready for your approval.
                        </Typography>

                        <Typography className={styles.infoText}>
                            For further information on our selling process,
                            please go to our{" "}
                            <a
                                className={styles.faqLink}
                                href="/"
                                onClick={handleFaqClick}
                            >
                                FAQ page.
                            </a>
                        </Typography>
                        {!user ? (
                            <div
                                className={
                                    isDesktop
                                        ? styles.authContainer
                                        : styles.authContainerMobile
                                }
                            >
                                <Typography className={styles.authText}>
                                    Before you can upload your watch, please
                                    sign up or login to your account
                                </Typography>
                                <Button
                                    variant="outlined"
                                    className={styles.authButton}
                                    onClick={handleLoginClick}
                                >
                                    <Typography className={styles.authBtnText}>
                                        Sign Up or Login
                                    </Typography>
                                </Button>
                            </div>
                        ) : !user?.email_verified ? (
                            <div
                                className={
                                    isDesktop
                                        ? styles.authContainer
                                        : styles.authContainerMobile
                                }
                            >
                                <Typography className={styles.authText}>
                                    Before you can upload your watch, please
                                    confirm your email address.
                                </Typography>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div
                            className={
                                isDesktop
                                    ? styles.detailsContainer
                                    : styles.detailsContainerMobile
                            }
                            style={
                                !user || !user?.email_verified
                                    ? { pointerEvents: "none", opacity: 0.4 }
                                    : {}
                            }
                        >
                            <Typography className={styles.watchDetailsInfo}>
                                Please answer the following questions and click
                                ‘Submit’.
                            </Typography>
                            <WatchDetails
                                user={user}
                                brands={brands}
                                setWatchBrand={handleChangeBrand}
                                watchBrand={watchBrand}
                                models={models}
                                variants={variants}
                                modelNumber={modelNumber}
                                setModelNumber={setModelNumber}
                                watchModel={watchModel}
                                setWatchModel={handleChangeModel}
                                sellInfo={sellInfo}
                                setSellInfo={setSellInfo}
                                handleSubmit={handleSubmit}
                            />
                        </div>
                    </div>
                    <div
                        style={
                            !user || !user?.email_verified
                                ? {
                                      gridColumn: 2,
                                      pointerEvents: "none",
                                      opacity: 0.4,
                                  }
                                : { gridColumn: 2 }
                        }
                        className={styles.rightInfoContainer}
                    >
                        <FileUploadComponent
                            disabled={!user || !user?.email_verified}
                            setFiles={setFiles}
                            files={files}
                        />
                    </div>
                </div>
            </div>

            <Footer hideInfoLinks={false} />
            <BackDrop open={isLoading} />
        </div>
    );
};

export default AuctionSell;
