import styles from './ComingSoon.module.scss';
import WhoAreWeImage from '../../../assets/whoAreWe.jpg';
import BackgroundImage from '../../../assets/backgroundImage.png';
import BuyingProcessImage from '../../../assets/buyJourney.png';
import SellingProcessImage from '../../../assets/sellJourney.png';
import { Button, Typography } from "@mui/material";
import Footer from '../../footer/Footer';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ComingSoon = () => {
    const navigate = useNavigate();

    const handleLearnMoreClick = useCallback(() => {
        navigate("/faq");
    }, [navigate]);

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className={styles.root}>
            <div className={styles.whoAreWeContainer1} id='whatiswatchshuffle'>
                <div className={styles.whoAreWeContainer2}>
                    <div style={{gridColumn: 1}} className={styles.whoAreWeImageContainer}>
                        <img className={styles.whoAreWeImage} src={WhoAreWeImage} alt="Who Are We" />
                    </div>
                    <div style={{gridColumn: 2}} className={styles.storyContainer}>
                        <Typography className={styles.header}>WELCOME TO WATCH SHUFFLE: A NEW AUCTION PLATFORM</Typography>
                        <Typography className={styles.content}>
                            Watch Shuffle is a premium online auction house that is honest, transparent and hassle free.
                        </Typography>
                        <Typography className={styles.content}>
                            Whether buying or selling, our goal is to deliver you the best value in the marketplace and to act as your trusted partner at each step of your journey.
                        </Typography>
                        <Typography className={styles.content}>
                            <b>Our Approach</b>
                            <ul>
                                <li><b>No selling fees</b> – an auction-based approach with no fee for listing or selling and low purchase fees.  You pay less when buying and get more when selling.</li>
                                <li><b>Expert curation</b> – our editing team will ensure your watch is beautifully photographed and that your listing has maximum impact.</li>
                                <li><b>Safe, secure and easy</b> – for Sellers, a specialist courier collects your watch which is insured  and held securely throughout the sales process. Buyers can be sure that the watch will be as photographed when it is delivered by our courier to your door.</li>
                                <li><b>Transparency</b> – every member of the Watch Shuffle community is vetted prior to listing or bidding on our platform.</li>
                                <li><b>Customer experience</b> – your sale matters to us. Our knowledgeable team is easily contactable throughout the buying or selling process and will be delighted to answer your queries.</li>
                                <li><b>Hybrid model</b> – sell remotely via our courier or drop your watch off in person at our Mayfair office.</li>
                            </ul>
                        </Typography>
                        <Typography className={styles.content}>
                            We invite you to browse our showroom and to contact our friendly team for more information.
                        </Typography>
                        <Typography className={styles.contentNoSpace}>
                            Fazal and Zahid
                        </Typography>
                        <Typography className={styles.content}>
                            Founders
                        </Typography>
                        <div className={styles.buttonContainer}>
                            <Button variant="outlined" className={styles.learnMoreButton} onClick={handleLearnMoreClick}>
                                <Typography className={styles.learnMoreText}>Go to FAQs</Typography>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.moreInfoContainer}>
                <div style={{gridColumn: 1}} className={styles.sellingProcessContainer}>
                    <div className={styles.sellingProcessTextContainer}>
                        <Typography className={styles.sellingProcessText}>Learn more about our buying process...</Typography>
                    </div>
                    
                    <img className={styles.sellingProcessImage} src={BuyingProcessImage} alt="Buy process" />

                    <div className={styles.sellingProcessTextContainer}>
                        <Typography className={styles.sellingProcessText}>Learn more about our selling process...</Typography>
                    </div>
                    
                    <img className={styles.sellingProcessImage} src={SellingProcessImage} alt="Sell process" />
                </div>

                <div style={{gridColumn: 2}} className={styles.signupContainer}>
                    <div style={{backgroundImage: `url(${BackgroundImage})`, backgroundSize: '100% 100%'}} className={styles.backgroundContainer}>
                        
                    </div>

                    {/* <div className={styles.emailContainer}>
                        <div className={styles.titleContainer}>
                            <Typography className={styles.titleText}>Be ready</Typography>
                            <Typography className={styles.contentText1}>Please register your email address for the latest updates!</Typography>
                            
                            <iframe className={styles.formContainer} title="SendGridSignUpForm" src="https://cdn.forms-content.sg-form.com/a22d90cc-b81c-11ed-a8e7-f211d972171f"/>
                        </div>
                    </div> */}
                </div>
            </div>

            <Footer hideInfoLinks={false} />
        </div>
    );
}

export default ComingSoon;