import styles from './AuctionBuyDetails.module.scss';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Footer from '../../../footer/Footer';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { WatchInfoBuyDetails } from '../../../../models/WatchInfoBuyDetails';
import { getWatchBuyDetails } from '../../../../services/auction-service';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Scrollbar, Mousewheel } from "swiper";
import ItemDetails from './ItemDetails';
import { useMediaQuery } from "react-responsive";
import LightGallery from 'lightgallery/react';
import { LightGallery as LightGalleryPlugin } from "lightgallery/lightgallery";
import { InitDetail } from "lightgallery/lg-events";
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { LightGalleryLicense, MobileScreenWidth } from '../../../../../constants';
import { MobileSettings } from 'lightgallery/lg-settings';
import Slider from "react-slick";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import NoPhotographyOutlinedIcon from '@mui/icons-material/NoPhotographyOutlined';
import { loggingService } from '../../../../../utils/logging/logging';
import Connector from '../../../../services/signalr-service';
import { SnackContext } from '../../../../providers/SnackProvider';

const {log} = loggingService('app:AuctionBuyDetails');

export interface AuctionBuyDetailsProps {
    watch?: WatchInfoBuyDetails;
    isPreview?: boolean;
    bodyRef?: React.RefObject<HTMLDivElement>;
}

const AuctionBuyDetails = ({watch, isPreview, bodyRef}: AuctionBuyDetailsProps) => {
    const internalRef = useRef<HTMLDivElement | null>(null);
    const combinedRef = bodyRef || internalRef;

    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });
    const [scrolling, setScrolling] = useState(false);
    const [searchParams] = useSearchParams();    
    const showSnackbar = useContext(SnackContext);
    const [isLoading, setIsLoading] = useState(true);
    const [watchDetails, setWatchDetails] = useState<WatchInfoBuyDetails|undefined>();
    const watchDetailsRef = useRef(watchDetails);
    watchDetailsRef.current = watchDetails;
    const [selectedSlide, setSelectedSlide] = useState<number>(0);
    const lightGalleryRef = useRef<LightGalleryPlugin>();
    const navigate = useNavigate();
    const {events} = Connector();

    const onInit = (detail: InitDetail) => {
        lightGalleryRef.current = detail.instance;
    };

    const handleGalleryShow = (e: React.MouseEvent<HTMLAnchorElement>, index: number) => {
        e.preventDefault();

        if (lightGalleryRef.current) {
            lightGalleryRef.current.openGallery(index);
        }
    }

    const mobileSettings: Partial<MobileSettings> = {
        showCloseIcon: true
    };

    const sliderSettings = {
        arrows: false,
        dots: false,
        beforeChange: (_: number, nextSlide: number) => setSelectedSlide(nextSlide),
    };

    const handleBackClick = useCallback(() => {
        navigate("/buy");
    }, [navigate]);

    const fetchWatchDetails = useCallback(async (watchId: string) => {
        try {
            const details = await getWatchBuyDetails(watchId);
            setWatchDetails(details);
        } catch (error) {
            log(`Error loading watch details for ${watchId}`, error);
            showSnackbar('An error occurred loading watch details. Please try again later.', {alertSeverity: 'error'});
        } finally {
            setIsLoading(false);
        }        
    }, [showSnackbar]);

    useEffect(() => {
        if (watch) {
            setWatchDetails(watch);
            setIsLoading(false);
        } else {
            const id = searchParams.get('id');
            if (id) {
                fetchWatchDetails(id);
            } else {
                setIsLoading(false);
            }    
        }
    }, [fetchWatchDetails, searchParams, watch]);

    useEffect(() => {
        events((notification) => {
            log('Received listing update notification', notification);

            if (watchDetailsRef.current && watchDetailsRef.current.id === notification.id) {
                log('Listing update received for currently displayed item.  Refreshing');
                fetchWatchDetails(watchDetailsRef.current.id);
                showSnackbar('Current listing has been updated', {alertSeverity: 'info'});
            }
        });
    }, [events, fetchWatchDetails, showSnackbar]);

    const onCountdownFinished = useCallback(() => {
        if (watchDetailsRef.current && watchDetailsRef.current.id) {
            log('Auction end time reached.  Refreshing');
            fetchWatchDetails(watchDetailsRef.current.id);
        }
    }, [fetchWatchDetails]);

    const updateFavCounts = useCallback((count: number, isFavourite: boolean) => {
        setWatchDetails((prev: any) => {
            return { ...prev, savedAsFavouriteCount: count, isFavourite }
        });
    }, [setWatchDetails]);
  
    useEffect(() => {
        const currentBodyRef = combinedRef.current;

        const handleScroll = () => {
            if(currentBodyRef){
                const isAtTop = currentBodyRef.scrollTop <= 100;
                setScrolling(!isAtTop);
            }
        };

        if (currentBodyRef) {
            currentBodyRef.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (currentBodyRef) {
              currentBodyRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [combinedRef]);
  
    if (isLoading) {
        return (
            <div className={styles.root}>
                <div className={styles.backButtonContainer}>
                    <Button className={styles.backButton} variant="contained" disableElevation onClick={handleBackClick}>
                        <KeyboardArrowLeftIcon className={styles.backIcon} />
                        <Typography className={styles.backLabel}>Back to Auctions</Typography>
                    </Button>
                </div>
                <Stack direction='row' className={styles.loadingContainer}>
                    <div className={styles.loader} />
                    <Typography className={styles.loadingLabel}>Loading</Typography>
                </Stack>
                <Footer hideInfoLinks={false} />
            </div>
        );
    }

    if (!watchDetails) {
        return (
            <div className={styles.root}>
                <div className={styles.backButtonContainer}>
                    <Button className={styles.backButton} variant="contained" disableElevation onClick={handleBackClick}>
                        <KeyboardArrowLeftIcon className={styles.backIcon} />
                        <Typography className={styles.backLabel}>Back to Auctions</Typography>
                    </Button>
                </div>
                <Stack direction='row' className={styles.noInfoContainer}>
                    <Typography className={styles.noInfoLabel}>No watch details found</Typography>
                </Stack>
                <Footer hideInfoLinks={false} />
            </div>
        );
    }

    if (isDesktop) {
        return (
            <div className={styles.root}>
                {!isPreview && (
                    <div className={styles.backButtonContainer}>
                        <Button className={styles.backButton} variant="contained" disableElevation onClick={handleBackClick}>
                            <KeyboardArrowLeftIcon className={styles.backIcon} />
                            <Typography className={styles.backLabel}>Back to Auctions</Typography>
                        </Button>
                    </div>
                )}
                <div className={styles.contentContainer}>
                    <div style={{gridColumn: 1}} className={styles.leftContentContainer}>
                        {watchDetails.images.length ? (
                            <Swiper
                                direction={"vertical"}
                                slidesPerView={"auto"}
                                freeMode={true}
                                scrollbar={true}
                                mousewheel={true}
                                modules={[FreeMode, Scrollbar, Mousewheel]}
                                className={styles.imagesContainer}
                            >
                                <SwiperSlide>
                                    <LightGallery
                                        licenseKey={LightGalleryLicense}
                                        speed={500}
                                        plugins={[lgThumbnail, lgZoom]}
                                        getCaptionFromTitleOrAlt={false}
                                        download={false}
                                        showCloseIcon={true}
                                    >
                                        {watchDetails.images.map(watchImage => 
                                            <a key={watchImage.mediaUrl} className={styles.imageOverlayLink} href={watchImage.mediaUrl}><img className={styles.watchImage} src={watchImage.thumbnailUrl} alt="Watch" /><ZoomOutMapIcon className={styles.overlayIcon} /></a>
                                        )}
                                    </LightGallery>
                                </SwiperSlide>
                            </Swiper>
                        ) : (
                            <Stack direction='column' className={styles.watchNoImageContainer}>
                                <NoPhotographyOutlinedIcon />
                                <Typography className={styles.watchNoImageText}>Image Unavailable</Typography>
                            </Stack>
                        )}
                    </div>
                    <div style={{gridColumn: 2}} className={styles.rightContentContainer}>
                        <ItemDetails 
                            watchDetails={watchDetails} 
                            onCountdownFinished={onCountdownFinished} 
                            updateFavCounts={updateFavCounts}
                            scrolling={scrolling}
                        />
                    </div>
                </div>
                
                <Footer hideInfoLinks={false} />
            </div>
        );
    } else {
        return (
            <div className={styles.root}>
                {watchDetails.images.length ? (
                    <>
                        <div className={styles.lightGalleryContainer}>
                            <LightGallery
                                onInit={onInit}
                                licenseKey={LightGalleryLicense}
                                speed={500}
                                plugins={[lgThumbnail, lgZoom]}
                                getCaptionFromTitleOrAlt={false}
                                download={false}
                                showCloseIcon={true}
                                mobileSettings={mobileSettings}
                            >
                                {watchDetails.images.map(watchImage =>
                                    <a key={watchImage.mediaUrl} href={watchImage.mediaUrl}><img alt="Watch" src={watchImage.thumbnailUrl} /></a>
                                )}
                            </LightGallery>
                        </div>
                        <div className={styles.mobileImagesContainer}>
                            <Slider {...sliderSettings}>
                                {watchDetails.images.map((watchImage, index) =>
                                    <div key={watchImage.thumbnailUrl} className={styles.imageContainer}>
                                        <a className={styles.imageOverlayLink} href='/' onClick={e => handleGalleryShow(e, index)}>
                                            <img className={styles.image} src={watchImage.thumbnailUrl} alt="Watch" />
                                            <ZoomOutMapIcon className={styles.overlayIcon} />
                                        </a>
                                    </div>
                                )}
                            </Slider>
                        </div>
                        {watchDetails.images.length &&
                            <div className={styles.mobileSliderDots}>
                                {watchDetails.images.map((_, index) =>
                                    index === selectedSlide ? 
                                        <CircleIcon key={index} className={styles.sliderDot} /> :
                                        <CircleOutlinedIcon key={index} className={styles.sliderDot} />
                                )}
                            </div>
                        }
                    </>
                ) :(
                    <></>
                )}

                <div className={styles.mobileItemDetailsContainer}>
                    <ItemDetails 
                        watchDetails={watchDetails} 
                        isPreview={isPreview} 
                        onCountdownFinished={onCountdownFinished} 
                        updateFavCounts={updateFavCounts}
                        scrolling={scrolling}
                    />
                </div>

                <Footer hideInfoLinks={false} />
            </div>
        );
    }
}

export default AuctionBuyDetails;