import { QuestionAndAnswer } from "./FAQItem";

export const buyerFaq: QuestionAndAnswer[] = [{
    id: "b1",
    question: "What are your fees?",
    answer: [
        "We charge a fee of 6%+VAT to the buyer on their final winning bid (minimum £500 + VAT).",
        "For example, if an auction ends at £10,000 the seller will receive £10,000. The buyer will pay £10,600 in total of which £10,000 will go the buyer and the remainder will cover our fee and the VAT on our fee.",
    ]
},
{
    id: "b2",
    question: "Do all watches have a reserve price?",
    answer: ["The vast majority will have a reserve price but if not, the lots without a reserve will be clearly marked as such. Lots that have met their reserve during an auction will also say as such when this happens."]
},
{
    id: "b3",
    question: "How do you prevent very last second bidding?",
    answer: ["Any bids received within the last two minute window of the auction will automatically add two minutes to the auction end time. We discourage last minute bidding this way and want buyers to feel comfortable when bidding and not feeling rushed by last second bidding practices."]
},
{
    id: "b4",
    question: "If the watch I receive is not as described, what happens next?",
    answer: [
        "Watch Shuffle provides details of the watch from the seller who confirms its accuracy. Watch Shuffle photographers take detailed photographs to allow any bidders to form an opinion of the watch and bid accordingly. In the case of a managed sale, the watch remains in secured storage after photography and so all parties know the watch is as shown and has not been used in the interim period between photography and being sold.",
        "Aside from this, it is the responsibility of the seller to be comfortable with the watch before bidding and we encourage any bidders to ask all relevant questions on the auction page before bidding.",
        "Watch Shuffle monitors all comments and removes any malicious posts.",
    ]
},
{
    id: "b5",
    question: "How much does it cost to register with Watch Shuffle?",
    answer: ["Registrations are free."]
},
{
    id: "b6",
    question: "Can I contact the seller for any queries I have relating to the watch?",
    answer: ["We encourage bidders to ask all relevant questions before bidding. All questions asked and answers given are visible for all site users to see."]
},
{
    id: "b7",
    question: "What happens after I win the auction?",
    answer: ["After the watch is sold having met or exceeded its reserve price, your card is automatically charged with the winning bid fee of 6%+VAT (min £500+VAT). This covers the Watch Shuffle fees and the balance is invoiced for payment to seller within 24 hours. You will be introduced to the seller virtually so that you can arrange payment and collection/delivery of the watch. In the case of a managed sale, you will be provided with the seller's payment details and once the seller has confirmed receipt of payment, your watch will be released to you. You are welcome to collect the watch from our office in Mayfair or we can arranged fully insured shipping."]
},
{
    id: "b8",
    question: "If the buyer does not pay for the watch during the allocated time, what happens?",
    answer: ["Should the buyer not follow through with payment, the buyer is liable for the Watch Shuffle fees of 6%+VAT (min £500 +VAT) of the final bid as per their contract with Watch Shuffle. They are then restricted from bidding on the platform and the watch is offered for auction again."]
},
{
    id: "b9",
    question: "Are you subscribed to the Art Loss Register?",
    answer: ["Yes. Watch Shuffle is registered with the Art Loss Register which is the largest database of lost and stolen watches. It's a platform that's used by insurance companies and all watches listed on Watch Shuffle will be processed by the Art Loss Register."]
},
{
    id: "b10",
    question: "Typically, how long do auctions last?",
    answer: ["Watches listed on the Watch Shuffle are auctioned for a period of 7 days."]
},
{
    id: "b11",
    question: "Can I visit your London premises to see the watches?",
    answer: ["We are very much an online business with watches dealt on our platform with no viewings possible for the watches. However, the winning bidder is welcome to come to our offices to collect their watch and join us for a tea/coffee."]
},
{
    id: "b12",
    question: "Is it possible to withdraw my bid?",
    answer: ["Once a bid has been placed, it cannot be withdrawn."]
},
{
    id: "b13",
    question: "What happens if I am the highest bidder but the reserve was not met?",
    answer: ["Watch Shuffle will be in touch with you and the seller in an attempt to facilitate a sale."]
},
{
    id: "b14",
    question: "How do you grade the condition of watches?",
    answer: [
        "The condition of each lot is graded on a scale of A-F by the consignee. The higher the grade, the closer to unworn the watch is.",
        "Grade A: unworn since purchase from the authorised dealer. Case +/- bracelet stickers may still be in tact",
        "Grade B: like new but showing some signs of wear. Some case +/- bracelet stickers may be in tact.",
        "Grade C: lightly worn. Superficial scratches and blemishes are evident.",
        "Grade D: worn with markings, none substantial. Case edges still sharp, no evidence of over polishing. Bracelet may have some stretch.",
        "Grade E: substantial markings to case/bracelet/dial/hands. Evidence of polishing. See condition pictures.",
        "Grade F: substantial dings may be present on case/bracelet/dial/hands. Substantial evidence of polishing with soft case +/- bracelet edges. Bracelet may have significant stretch. See condition pictures.",
    ]
},
{
    id: "b15",
    question: "What are the bid increments?",
    answer: [
        "Auctions start at £1 and go up in the following increments:",
        "£100 increments to £10,000",
        "£200 increments from £10,001 to £25,000",
        "£300 increments from £25,001 to £50,000",
        "£500 increments from £50,001 upwards",
    ]
}];

export const sellerFaq: QuestionAndAnswer[] = [{
    id: "s1",
    question: "What are the fees to list with Watch Shuffle?",
    answer: ["There are no fees to list with Watch Shuffle. The seller receives the full amount achieved at auction."]
},
{
    id: "s2",
    question: "How do I know if you'll be interested in my watch?",
    answer: ["We love watches so the chances are high we'll be interested in yours. Please fill in the details here and we will get back to you with the market value for your watch."]
},
{
    id: "s3",
    question: "How do you set a reserve price?",
    answer: ["Based upon on our watch values database, we can indicate what your watch will typically achieve at auction and discuss this with you. We will guide you on this process but final approval rests with what you are comfortable with."]
},
{
    id: "s4",
    question: "How will you promote my watch to ensure it gets the widest audience reach?",
    answer: ["We promote our website and the watches through an extensive marketing campaign across social media, TV advertising spots, TV sponsorship credits, outdoor advertising and promotions to ensure we have a wide reach. We use analytics to ensure we're targeting effectively and we are building a reputation as a site that consigns excellent timepieces."]
},
{
    id: "s5",
    question: "Will you photograph my watch?",
    answer: [
        "There are two options for photography. We can arrange photography of your watch by our photographer if you choose the managed sale option. Otherwise we can arrange another photographer to meet with you and photograph your watch. Details on request.",
        "The premise of Watch Shuffle is to be transparent. Our photographs showcase the watches exactly as they are with minimal post production. This allows any bidder to bid with confidence given they can see macro images of the watch they're interested in.",
        "Photography is charged at £100 per watch."
    ]
},
{
    id: "s6",
    question: "Tell me more about a managed sale",
    answer: [
        "The prospective seller contacts us with their watch details and we discuss pricing. If they are happy with the valuation, they send the watch to us and we take photographs of the watch before storing the watch at our secured facility. Watch Shuffle curates the description for the seller to approve after which the watch joins the queue to go live. Once the watch is sold, the buyer pays the seller and when the seller confirms receipt of funds, Watch Shuffle releases the watch to the buyer. The watch can be picked up at our Mayfair offices or delivered to the new owner.",
        "The watch is fully insured by our trade insurance from the moment we receive the watch until it is received by the buyer. If the watch does not sell, Watch Shuffle will cover postage back to the seller and the watch remains insured until safe receipt by the seller.",
        "Our buyers and sellers prefer the managed sale process because the buyer bids on the watch as seen in the photographs and the seller lets Watch Shuffle handle the end to end process for them.",
        "The managed sale fee includes the cost of photography and is £150+vat"
    ]
},
{
    id: "s7",
    question: "How do I send my watch to Watch Shuffle?",
    answer: [
        "At Watch Shuffle, we're watch enthusiasts at heart and will look after your watch with care and attention. You are welcome to drop your watch off to us at our Mayfair offices or if you prefer to post, we can advise on courier and insurance options.",
        "The cost of delivery and insurance of your watch to Watch Shuffle's premises in London is met by the seller.",
        "In some instances and if seller prefers, Watch Shuffle could collect the watch but this will be dependent on location. Our insurance will cover your watch from the moment we collect it.",
    ]
},
{
    id: "s8",
    question: "Are you insured?",
    answer: ["Yes, your watch is fully insured while it is in our care which starts from when we receive your watch - in person or by courier or when we collect your watch."]
},
{
    id: "s9",
    question: "If I change my mind, can I withdraw my watch?",
    answer: ["Once the watch is advertised as coming soon, any withdrawals will be met with costs as set out in our contract.",]
},
{
    id: "s10",
    question: "When my watch sells, what happens next?",
    answer: [
        "When the auction has ended and your reserve price has been met or has been exceeded, we take the 6%+VAT fee from the buyer and virtually introduce buyer and seller. Buyer and seller can then arrange payment and delivery of the watch. Buyer will need to adhere to all lot specific terms that the seller has stipulated eg. the use of escrow.",
        "In the case of a managed sale, Watch Shuffle will send payment details to the buyer and once the seller confirms receipt of the payment, the watch can be collected or delivered to the buyer.",
    ]
},
{
    id: "s11",
    question: "How much does it cost to register with Watch Shuffle?",
    answer: ["Registrations are free.",]
},
{
    id: "s12",
    question: "Do you ID check the Watch Seller?",
    answer: ["In accordance with good practice and watch industry standards, lots will only be accepted for listing after the seller has provided ID which has been verified by our ID verification partners.",]
},
{
    id: "s13",
    question: "What happens if my watch appears on the Art Loss Register?",
    answer: ["Watch Shuffle is contractually bound to share information with the register and will pass details onto them to investigate further.",]
},
{
    id: "s14",
    question: "What happens if my watch fails to sell?",
    answer: [
        "Should this happen, Watch Shuffle will contact you with two options:",
        "If the highest bid is acceptable to you, we will facilitate the sale else if you prefer, we can relist the watch for you.",
    ]
},
{
    id: "s15",
    question: "What are the bid increments?",
    answer: [
        "Auctions start at £1 and go up in the following increments:",
        "£100 increments to £10,000",
        "£200 increments from £10,001 to £25,000",
        "£300 increments from £25,001 to £50,000",
        "£500 increments from £50,001 upwards",
    ]
}];