import { TermsAndConditions } from "./TermsItem";

export const termsConditons: TermsAndConditions[] = [{
    id: "b1",
    question: "WHO WE ARE",
    answer: [
        `“Watch Shuffle” / “we” / “us” and “our” means Watch Shuffle Ltd, company registration number 14074692. Our registered office is at J O Hunter House, 409 Bradford Road, Huddersfield, West Yorkshire, England, HD2 2RB.</br></br>`,
    ]
},
{
    id: "b2",
    question: "ABOUT OUR TERMS",
    answer: [
        `These Terms explain how you may use <a href='https://watchshuffle.com' target="_blank">www.watchshuffle.com</a> (the “Site”). In addition to these terms, the following Privacy Policy and Cookie Policy apply to your usage of <a href='https://watchshuffle.com'>Watchshuffle</a>.</br></br>`,
        `The Watch Shuffle privacy policy is available here <a href='https://watchshuffle.com/privacy' target="_blank">www.watchshuffle.com/privacy</a>. This sets out the terms by which we handle your personal information; and</br></br>`,
        `The Watch Shuffle cookie policy is available <a href='https://watchshuffle.com/cookies' target="_blank">www.watchshuffle.com/cookies</a>. This sets out information about the cookies on our Site.</br></br>`,
        `It is important for you to read these Terms carefully before you use this Site.</br></br>`,
        `Accessing or using the Site or by otherwise indicating your consent, you agree to be legally bound by these Terms and the documents referred to in them.</br></br> `,
        `If you do not accept or agree with any of these Terms, you must immediately cease using the site.</br></br>`,
        `Complaints or questions relating to the Site can be relayed to us by emailing us at <a href="mailto:hello@watchshuffle.com">hello@watchshuffle.com</a>.</br></br>`,
        `Complaints received by us in relation to our services will be acknowledged within two working days.  Watch Shuffle will instigate and conduct a subsequent investigation. If it is established by us at the conclusion of our investigation that there has been a shortcoming in our service, we shall at our sole discretion, take any of the following actions:</br></br>`,
        `Remedy the shortcoming in our service; or</br></br>`,
        `where fees have already been paid by you to us, make a fair and proportionate refund of these fees; or</br></br>`,
        `where fees have not been paid by you, reduce or, where appropriate, waive any fees.</br></br>`,
        `If you feel your complaint has not been resolved to your satisfaction, you are welcome to email or write to our directors by marking your correspondence for the attention of the directors and sending it to <a href="mailto:hello@watchshuffle.com">hello@watchshuffle.com</a>.</br></br>`,
        `Residents of the EU, Norway, Iceland or Liechtenstein, you can seek helps from the Online Dispute Resolution platform <a href="https://ec.europa.eu/consumers/odr/main/?event=main.home2.show" target="_blank">here</a>.</br></br>`,
        `English is the prevailing language of all Terms related to the use of <a href="https://watchshuffle.com" target="_blank">www.watchshuffle.com</a> Where there has been a translation made, the English original shall prevail.</br></br>`
    ]
},
{
    id: "b3",
    question: "DEFINITIONS",
    answer: [
        `“Auction Lot” or “Lot” refers to any items proposed or accepted for listing on the Site.  This can include watches any accessories that came with the watch at the point of original sale.</br></br>`,
        `“Buyer” refers to a person or entity who has registered with us.  Registration includes registering a payment card for the payment of any fees which might become due. Successfully registered buyers can make bids for the purchase of an auction lot;</br></br>`,
        `“Live Auction” refers to an auction which has started and has not been concluded;</br></br>`,
        `“Papers” refers to the guarantee/warranty certificates issued by the supplying dealer at the point of original retail sale of the Lot.</br></br>`,
        `“End to End Managed Service” refers to the courier transportation, inspection, insured storage, photography and curation of your Auction Lot listing by us.  This includes full management by us of the post sales process.</br></br>`,
        `“Seller” refers the person who registers with us and is accepted to list an Auction Lot for sale;</br></br>`,
        `“Terms” refers these terms and conditions of use as updated from time to time;</br></br>`,
        `“Trade Seller” refers to a Seller who is not acting as a consumer and/or is selling as part of a trade or business;</br></br>`,
        `“Winner” or “winning bidder” refers to the site member who has placed the winning bid for an Auction Lot.</br></br>`,
        `“Working Days” refers to Monday to Friday except public holidays as applicable in London, UK;</br></br>`,
        `“you” and “you” refers to a private or trade buyer or seller or any other person who accesses <a href="https://watchshuffle.com" target="_blank">www.watchshuffle.com</a></br></br>`
    ]
},
{
    id: "b4",
    question: "USING THE SITE",
    answer: [
        `Watch Shuffle operates an auction platform on behalf of independent buyers and sellers.  We are not agents of the seller or the buyer.  We are not a party in the contract between buyer and seller to buy/sell an auction lot.</br></br>`,
        `Any and all costs you may incur in the course of your use of the site are your responsibility to pay and settle. This does not affect your consumer rights which you fully retain.</br></br>`,
        `Your access to the site may be withheld or suspended (including the removal or any of your listings or bids) if you do not adhere to any part of these Terms, any terms or policies which are referred to and any applicable law.</br>`,
        `By using the site, you agree to:`,
        `Refrain from using the site for any reason that is unlawful or in contravention of these Terms;`,
        `Refrain from using the site to commit any act of fraud;`,
        `Refrain from using the site to sell any counterfeit goods;`,
        `Refrain from using the site or accessing the site to distribute any viruses, malware or any other harmful code;`,
        `Refrain from using the site to propagate spam or promote unsolicited advertising;`,
        `Refrain from using the site in an attempt to phish which includes mimicking us in order to collect identity information, passwords or other information;`,
        `Refrain from using our site in any manner that might disturb our business or the business of any other entity;`,
        `Refrain from using the site in any way that might harm minors or to promote unlawful behaviours;`,
        `Refrain from suggesting we endorse any other business, product or service unless we consent to this in writing;`,
        `Refrain from using our site to attempt to gain access to our networks, data, computers or systems;`,
        `Refrain from attempting to circumvent user authentication methods including passwords;`,
        `Refrain from circumventing the cancellation or your account by us by opening a new account and appearing as a new user on our site`,
        `And you also confirm by using the site that you, or any person you act for, and this may include the owners, directors or managers of your business if you are a trade user are sanctioned in the UK, EU or USA or based in any country that is subject to sanctions from the UK, EU or USA.`,
        `It is forbidden for you to bid in your own lot or the lot of someone you are connected to nor can you ask anyone connected to you to bid on your auction lot.  In the case of a trade user of the site, forbidden bidding will  make you liable to us and indemnify us for losses or costs, including reasonable legal fees we incur in the course of your breach of this clause.</br></br>`
    ]
},
{
    id: "b5",
    question: "YOUR ACCOUNT WITH US",
    answer: [
        `Registration to use <a href="https://watchshuffle.com" target="_blank">www.watchshuffle.com</a> is open to all those aged 18 or above.  Registration will follow once you have provided your first and last names and your email address;</br></br>`,
        `We use third parties, including Stripe, to verify the identity of those wishing to bid or list items for auction on our site.  Use of our site follows successful identity verification;</br></br>`,
        `Bidders are required to provide a valid payment method before being authorised to place bids.  This is to allow for payment of our fees per our fee schedule or any other charges that arise.  For sellers, a valid payment method is required for payment of your listing fees and other charges that may arise. Any fees that a Buyer or Seller’s bank imposes in connection with a payment to Watch Shuffle are the responsibility of the respective Buyer or Seller only.</br></br>`,
        `Stripe and Watch Shuffle will make checks to confirm your identity and to prevent financial crime.  You will be prompted to provide information to verify your identity when trying to bid for the first time or trying to list a watch for auction.  If you do not agree to provide this information, you cannot use the site to buy or sell.</br></br>`,
        `Passwords, user authentication/identification codes or other information disclosed to you as part of our security measures must be kept safely and confidentially by you.  If you have reason to think your password or other security measure information has been compromised or is known by any third party, you must notify us immediately.</br></br>`,
        `Any activity on your account as a result of you providing access to your account to a third party is your responsibility, including the payment of fees arising out of such access.</br></br>`
    ]
},
{
    id: "b6",
    question: "LISTING A WATCH FOR SALE ON OUR SITE",
    answer: [
        `The process starts with you submitting a request to sell through the sell tab on our website.  You will be asked for photos and other particulars of the watch. Further information can be found in our <a href="https://watchshuffle.com/faq" target="_blank">FAQs</a> and our <a href="https://watchshuffle.com/sell" target="_blank">Sell</a> section.</br></br>`,
        `Watch Shuffle will consider the proposed Auction Lot and confirm in writing if the Auction Lot is accepted to be listed on the Site. Watch Shuffle will help sellers with the curation and wording of their listing, however, it remains the seller’s sole responsibility to ensure the full content of their listing is correct and accurate.  The period prior to the auction going live can last up to 15 days between your initial enquiry and the auction being ready to accept bids.</br></br>`,
        `Upon the auction lot being accepted, you are committing to the auction lot being listed exclusively with Watch Shuffle until the conclusion of the Auction or any subsequent period where the auction lot may be relisted for auction.  For the avoidance of doubt, during the period after acceptance of the lot for auction and the conclusion of the auction, you may not seek to sell the auction lot by any other means, unless we agree to this in writing.</br></br>`,
        `You can specify a reserve price for your auction lot which must be agreed by us prior to being accepted.  Upon agreement of a reserve price, the reserve can be revised downwards or withdrawn by you with written confirmation, however, any increase in the reserve price requires our prior approval.  Further information can be found in our  <a href="https://watchshuffle.com/faq" target="_blank">FAQs</a>.</br></br>`,
        `Your watch will be held securely by Watch Shuffle for the purposes of auction curation and also throughout the auction process.  Acceptance of your auction lot for auction is subject to due diligence checks including a search through the The Watch Register (a subsidiary of the Art Loss Register) and also to an inspection of condition.</br></br>`,
        `It is important to note that should your auction lot match an item on the Watch Register’s database of stolen watches, Watch Shuffle is legally bound to secure the watch and where required, inform law enforcement.  In this event, you would need to liaise with The Watch Register directly to resolve any issues.</br></br>`,
        `Estimated selling prices provided by Watch Shuffle, in writing or verbally, are opinion only and may be subject to revision by Watch Shuffle. They should not be relied upon as an indication of actual selling price.</br></br>`,
        `On acceptance of your lot for auction, you have the option of hand delivering your lot to our office in Mayfair, London or to have our specialist jewellery courier collect from you directly. Please note, although most cities in the UK are covered by our jewellery courier, some rural locations are excluded. We can advise of alternative insured shipping options if your post code is not covered by our jewellery courier.</br></br>`,
        `Our secure jewellery courier provides end to end tracking and will hand the secured parcel to named Watch Shuffle personnel only, on production of their ID documents.  Our courier transports parcels in armoured vehicles.</br></br>`,
        `If you hand deliver your lot to our Mayfair office, insurance begins at the point of receipt of the lot by Watch Shuffle.  If you opt for courier delivery, insurance cover for your lot begins on receipt of your lot by Watch Shuffle personnel at our Mayfair office.</br></br>`,
        `Seller’s take full and sole responsibility for the accuracy of the auction lot description.  Sellers are responsible for all errors or misstatements or omissions of information in the description.  Sellers are confirm that all items listed for sale with Watch Shuffle are authentic in their entirety to the best of their knowledge.</br></br>`,
        `Sellers confirm they are the legal owners of auction lots or have been authorized by the legal owner to sell the auction lot.  Sellers agree to complete the sale of auction lots to the successful high bidder free or all encumberences, charges and any additional sales taxes.  Upon receipt of payment, sellers agree to transfer title of the auction lot to the successful buyer through the electronic signing of a transfer of title document.  Sellers are not allowed to add VAT or other charges to the hammer price of an auction.   Therefore if such tax or charges are chargeable they must be absorbed into the hammer price of the auction lot by the seller.</br></br>`,
        `Withdrawal of auction lots by a seller once accepted by Watch Shuffle or failure to complete the sale of a successfully auctioned auction (unless there is default on the part of the buyer) lot will result in the levying of fees by Watch Shuffle to the seller.  In the case of withdrawal of a lot by a seller before the commencing of the auction, fees of 6% of the reserve price or £600, whichever is higher, will be due.  If a successfully concluded auction does not result in the sale of the lot (unless in the case of buyer default), the seller will be liable to pay 6% of the hammer price in fees or £600, whichever is higher.</br></br>`,
        `Return, uninsured, shipping via Royal Mail Special Delivery of your Lot in the event of failure to complete the sale post auction or withdrawal of the lot prior to the auction commencing will be charged at £50 or you can collect the Lot from our Mayfair offices by mutual agreement.  Insured return shipping will be charged at £150 with a maximum payout value of £10,000.  Lots with a value exceeding £10,000 will have return insured postage quoted on a case by case basis and charged at cost.   Return shipping will be arranged once Watch Shuffle fees have been settled in full.</br></br>`,
        `For lots accepted for auction, there is an inward shipping and photography fee of £150 per watch listed, payable on acceptance of your Lot for auction by Watch Shuffle. Shipping is via a specialist jewellery courier.  Photography is in house by the same professional photographer commissioned for all Watch Shuffle photographs published to date.  The £150 includes secure storage costs and curation and promotion of your lot’s listing on www.watchshuffle.com.</br></br>`,
        `In auctions where a seller’s reserve has not been met, Watch Shuffle reserves the right to make up any shortfall to the reserve from the high bid and pay the seller this shortfall directly (thereby meeting the reserve set for the lot).  This decision can be made independently by Watch Shuffle without entering into a separate discussion with the seller.  This will be known as a shortfall payment.  This payment will be made by Watch Shuffle directly to the seller in the same timeframe required of payment by buyers to sellers of lots sold on <a href="https://watchshuffle.com" target="_blank">www.watchshuffle.com</a>.`,
        `If the buyer fails to send payment to the seller for the successfully completed auction, the seller agrees to return the shortfall payment sent by Watch Shuffle within 5 calendar days of being notified by Watch Shuffle that the buyer has failed to complete the transaction.  In such cases, the buyer’s account with Watch Shuffle will be deleted.  The seller acknowledges and agrees that payment of the shortfall payment to them by Watch Shuffle is an independent transaction and does not make Watch Shuffle a party to the contract between buyer and seller for the auction lot.</br></br>`,
        `On successful completion of an auction where the reserve for an auction lot has been met, the seller’s bank details will be shared with the buyer for the purposes of the buyer paying the seller for the auction lot.  Payment will go directly from buyer to seller.  The seller will, on receipt of the payment, complete a transfer of title document containing the particulars of the transaction including, make, model, serial number, details of accessories, buyer and sellers details along with confirmation they have received payment in full and that there are no outstanding taxes or finance on the lot and that it has not been subject to an insurance claim and that they are now transferring title of the lot to the buyer.  This document will be shared with the buyer and will act as their proof od purchase of the lot from the seller.</br></br>`,
        `The buyer will be invited to the watch shuffle offices by appointment to collect the auction lot. The auction lot will be handed over once seller has confirmed receipt of payment for the auction lot. If buyer prefers, insured shipping can be arranged by Watch Shuffle for the lot and will be charged at cost on a case by case basis depending on the value of the lot.</br></br>`
    ]
},
{
    id: "b7",
    question: "SELLERS WHO ARE TRADERS",
    answer: [
        'This clause applies to Trade Sellers.</br></br>',
        'Watch Shuffle retains the right to cancel your use of our site and your account with us at any time and will give not less than 30 calendar days’ notice.  You use of the site and your account may also be suspended if we suspect that you have breached the terms and having been notified of the breach, you have not rectified the breach within 7 calendar days, or if we believe or suspect that your account has been used for the purposes of committing fraud or illegal activity or if your use of our site is deemed to be detrimental to the interests of other buyers, sellers or Watch Shuffle’s interest.  You will be notified of cancellation or suspension of your account and any options you have to reinstate your account and what will be required for this to happen.</br></br>',
        'You retain your right to cancel your account at any time and can do this by emailing us or notifying us by other means.</br></br>',
        `If it is useful to you, you may ask us for date on the use of  our site including sales volumes, prices, frequencies, and dates of transactions which we will aim to make available to you in 5 calendar days.  This is solely intended to assist you for business purposes.</br></br>`,
        `By using the site, you acknowledge and accept that in certain cases, buyers of lots in the UK and EEA (European economic area) may be entitled to a 14 day right of withdrawal from the contract of sale of an auction lot.  You will obtain your own legal advice and will fully comply with this right, where applicable.</br></br>`
    ]
},
{
    id: "b8",
    question: "Language of Communication",
    answer: ['All communications, documentation, and notices concerning this contract will be conducted in English. Should any translation be provided, the English version will take precedence.']
},
{
    id: "b9",
    question: "SELLER CONTENT AND DESCRIPTION",
    answer: [
        `Watch Shuffle requires buyers to seek clarification for all queries before placing bids.  Any errors, omissions or misstatements in the description of an auction lot are the responsibility of the seller and Watch Shuffle shall not be liable for any such issues.</br></br>`,
        `The description of every auction lot is, to the best knowledge of the seller, accurate and without important omissions.  The seller is required to immediately notify Watch Shuffle If they become aware of:</br></br>`,
        `Any error, misstatement or omission in the lot description.</br></br>`,
        `Any material alterations that have been made to the auction lot not listed in the lot description,</br></br>`,
        `Any concerns the seller is aware of regarding authenticity, origin or quality of the auction lot.</br></br>`,
        `If the seller notifies Watch Shuffle of any additional and relevant information during a live auction, Watch Shuffle, may, at it’s sole discretion, add information to the auction lot description.</br></br>`,
        `As a Seller, you are required to assume all risk and liability that may arise out of third parties relying on the accuracy and completeness of the auction lot’s description.</br></br>`,
        `Watch Shuffle, to the extent permitted by law, has no duty to verify lot descriptions that buyer or seller may rely on during the auction process.</br></br>`,
        `If sellers fail in their duty to fully disclose information relating to their auction lot and there are errors, omissions, inaccuracies or misleading information in the auction lot or the seller fails to notify Watch Shuffle of all reasonably expected information regarding an auction lot including provenance, title, authenticity or any other aspect of the auction lot whose omission from the description leads to the loss of a sale, the seller will be liable to fees to cover the loss of a sale. Fees chargeable will be 6% of the reserve or 6% of the hammer price where a reserve has been met or £600, whichever is highest.</br></br>`,
        `The Buyer and the Seller acknowledge and agree that any information in the auction lot description, including photos, is provided as general information and should not be solely relied upon for decisions to make a bid or purchase.  Information provided should not be relied upon as a warranty, endorsement or guarantee or representation by Watch Shuffle or the seller.  Watch Shuffle does not guarantee the completeness or accuracy of the information in the description of an auction lot and does not accept any liability arising our of reliance on such information by the buyer or any third party.  Any claims against Watch Shuffle arising out of reliance by buyer, seller or third parties on information contained or omitted from auction lot descriptions will be dismissed by those bringing claims and Watch Shuffle shall be released against any such claim.</br></br>`,
        `While Watch Shuffle charges a fee for insured shipping, photography, storage and curation of the auction lot listing, the photographs remain the property of Watch Shuffle and cannot be used by any third party for any purpose without the written permission of Watch Shuffle.</br></br>`,
        `All photographs on our site are our intellectual property and use by third parties without written permission from Watch Shuffle will be subject to fees being charged for usage. We do not actively sell rights to use our photos, however, any third party usage is done so on the basis that Watch Shuffle will charge fees for such usage. Please contact us for details on fees and terms for using our photos.</br></br>`,
        `Photographs and illustrations used for the Auction Lots are for identification purposes only. They may not show the true condition of the Auction Lot. </br></br>`
    ]
},
{
    id: "b10",
    question: "BIDDING FOR A WATCH ON OUR SITE",
    answer: [
        `By bidding on an auction lot you are responsible for:</br></br>`,
        `Studying the auction lot description and reviewing photographs that form part of the description of the auction lot;</br></br>`,
        `Ask Watch Shuffle for an inspection of the auction lot, which may be arranged via video call;</br></br>`,
        `Checking the accuracy of your bids and acknowledging that all bids are made at your own risk and you are liable for any fees due to Watch Shuffle arising out of you making bids;</br></br>`,
        `have a bank account from which you can transfer payment of the lot to the seller;</br></br>`,
        `checking the auction lot at the point of collection or receipt via courier;</br></br>`,
        `independently determining that the description and authenticity of the lot are true; and pay shipping and, if opted for, insurance costs associated with delivery of your won auction lot.</br></br>`,
        `As a Buyer you agree that by bidding on any auction lot:</br></br>`,
        `you have reviewed the listing description and photographs and you are placing your bid armed with all information in the lot description;</br></br>`,
        `every bid has associated legal obligations and if your bid meets the reserve of an auction or in the case where there is no reserve or a reserve has been waived by a seller for their lot, fees will become payable by you to Watch Shuffle and you must pay our fees per our fee schedule; and </br></br>`,
        `if you win the auction, you are making an irrevocable offer to purchase that auction lot and you must forward payment to the seller according to the seller bank details provided to you by Watch Shuffle within 25 hours of auction end; and you have no right to withdraw any bid.</br></br>`,
        `Winning an auction requires you to forward payment to the seller to the bank details provided by Watch Shuffle within 25 hours of the auction end. These details will be forwarded to you within 1 hours of the auction end by Watch Shuffle. If you fail to pay the seller in this timeframe, you will still be required to pay Watch Shuffle’s fees levied to successful buyers per our fee schedule.  Watch Shuffle will assist the seller in attempting to sell the auction lot to the underbidder (if applicable) if you default in your duty to pay the seller per the terms listed. If the seller defaults in any way that causes failure of the sale to be concluded, the buyer’s obligation to pay fees shall be waived and any fees already collected will be refunded.  The seller will then become liable to pay the same fees that the buyer would have paid, according to the Watch Shuffle fee schedule, and these fees will be taken from the seller’s registered card without further discussion with the seller.  The seller’s account with Watch Shuffle will be suspended.</br></br>`,
        `If a Buyer makes the highest bid on the Auction Lot but the reserve has not been met, we retain the right to provisionally sell the lot.  The means that the seller will be given 24 hours to decide if the high bid is acceptable to them as a final price for the auction.  During this period, we will make attempts to contact the seller to determine if they accept the high bid as the final sales price.  If the seller if willing to accept the high bid as the sales price of the auction lot, we will notify the buyer and the post auction will proceed as normal.  If we do not hear from the seller, we will assume that they do not accept the high bid as a sales price for the auction lot.  If the buyer agrees, we can extend the 24 hour period to allow time for the seller to consider their options and communication their decision back to us.  Watch Shuffle retains its right to make a shortfall payment where Watch Shuffle pays the seller any shortfall from the high bid to the set reserve.  In this case, the sale will proceed with the buyer paying the amount of their high bid to the seller, the shortall from the reserve being paid by Watch Shuffle to the seller.  Watch Shuffle will share payment details with the buyer.  In a case where a seller accepts the high bid or we make a shortfall payment, the lot will be sold and fees will be payable to Watch Shuffle from the buyer per our fee schedule.</br></br>`,
        `Watch Shuffle is not a party in the sales contract between buyer and seller of any auction lot.</br></br>`,
        `Watch Shuffle retains the right to remove any if: `,
        `Any bidder has not provided an up to date payment method for our fees, this includes scenarios where you were successfully able to register an account with us which requires pre authorization of a payment card but subsequently, that card was no longer valid or able to payment to cover our fees. Watch shuffle requires buyers and sellers to maintain a valid means of payment on account at all times to enjoy uninterrupted use of our site;</br></br>`,
        `It is very clear that you have made a typographical error when placing your bid;</br></br>`,
        `We believe that the seller if bidding on their own lot using a secondary account that they have access to or they have asked someone known to them to use a third party account to bid on a lot; or</br></br>`,
        `One or more of the background checks we have made on you has failed.</br></br>`,
        `Buyers and sellers warrant that all sums paid to Watch Shuffle and from buyer to seller have no link to criminal activity, including but without limitation, money laundering, terrorist financing or tax evasion.  Those making payments agree to provide recipients or their bank of information that the bank may need to meet compliance obligations and this may include photo identification.</br></br>`,
        `Watch Shuffle may from time to time make bids on its own behalf for the purposes of testing or maintenance.</br></br>`,
        `A Buyer is obliged to transfer the full purchase price for an Auction Lot to the Seller prior to making an in-person collection at the Watch Shuffle offices or prior to requesting shipping of their won auction lot. It is the responsibility of the Seller to ensure that they are in receipt of the cleared purchase price prior to authorising Watch Shuffle to release an Auction Lot to a Buyer.</br></br>`,
        `On completion of an auction, a transfer of title document will be sent by us to the seller detailing the particulars of the watch and the bank details of the seller.  Signing of this online document through our partners at docusign will act as a receipt from seller to the buyer and will act as proof that the item has been paid for and transfer of title has taken place.</br></br>`
    ]
},
{
    id: "b11",
    question: "AUCTION PROCESS AND SALES OUTSIDE WATCH SHUFFLE",
    answer: [
        `Watch Shuffle retains the right to withdraw any lot, either coming soon or live auction, at its absolute discretion.  No reason need be provided by Watch Shuffle in exercising this right. In addition, Watch Shuffle retains the right to extend the end time of any auction that has been affected by delays or interruptions or if errors in the description affected the smooth running of the auction.  We retain the right to extend the auction period for any period we determine.  IN such cases, any bids already placed remain valid and cannot be withdrawn.  Bidders who are bidding, do so on the understanding that live auctions may need to be extended and should allow for this when planning on means to pay fees that may become payable.  d. If a Live Auction is extended all bids remain open and you cannot withdraw your bid.</br></br>`,
        `Buyers and Sellers are not permitted to engage in activity that would allow for the sale of action lots that are live or coming soon outside of our site.  This would be seen as an attempt to circumvent the payment of fees.  If any such case arises, the seller would become liable to pay our fees which would be charged at 6% of the agreed reserve or where there is no reserve, £600, whichever is higher.</br></br>`,
    ]
},
{
    id: "b12",
    question: "DISPUTE RELATING TO A SALE AND BUYER PROTECTION",
    answer: [
        `Disputes that arise between buyer and seller must be resolved between those two parties.  Watch Shuffle is not a party to the sales contract and will not participate in arbitration between parties.</br></br>`
    ]
},
{
    id: "b13",
    question: "OUR FEES",
    answer: [
        `Generally, using the site is free for sellers, except where stated otherwise in these terms and conditions.</br></br>`,
        `When you first register on our site, your card will be pre-authorised.  Successful pre-authorisation means that you are cleared to bid on items and that if you act as a seller, you have registered means to pay any charges that might arise per these Terms.  You will be providing credit or debit card information directly to our payment processing provider Stripe, which operates a secure server to process payment details, encrypting and tokenising your credit/debit card information, processing any pre-authorisations and authorising payment. By registering to make payment to us using Stripe you confirm that you accept and agree to be bound by Stripe’s terms and acknowledge that they constitute a legally binding contract between Stripe and you.</br></br>`,
        `Where the reserve price of an auction lot has been met and the buyer is the highest bidder, or where there is no reserve and a buyer is the highest bidder at the end of an auction, the buyer will be liable for fees.  These fees are calculated as 6% of the sales price of an auction lot or £600, whichever is highest.  This fee will be taken by Watch Shuffle from your registered card via our payment processing partner Stripe.</br></br>`,
        `Your credit card or debit card will only be charged if you are successful in the auction in which case immediate payment shall be taken by Watch Shuffle for our fees in accordance with these terms. The fees payable to Watch Shuffle are non-refundable; your legal rights as a consumer are not affected.</br></br>`,
        `In the event a Buyer fails to collect an Auction Lot that is held by Watch Shuffle seven calendar days after the conclusion of the auction and successful payment has been made to the seller, then Watch Shuffle may charge the Buyer a storage fee of £10 per day. This fee will be taken from the buyer’s card without any further consent being sought or gained from the buyer.</br></br>`,
        `Buyers and sellers are responsible for ensuring there are sufficient funds available on their registered payment card to make payment of watch shuffle fees.  It is the responsibility of buyers and sellers to ensure their registered payment card is valid.  If a buyer’s payment card is not valid and we remain unable to take payment for fees despite notifying the buyer, their winning bid may be cancelled by us without notice but our fees would still be payable.</br></br>`,
        `Our automated process takes payment from buyers at the conclusion of the auction per our fee schedule.  If we are unable to automatically take payment owing to a problem with your payment card, we will continue to attempt to take payment until our fees have been paid in full.  If payment cannot be taken and 24 hours has elapsed since the end of the auction, we will send you bank details of our company bank account and qill require you to make direct payment of cleared funds into our account.  If we do not receive this fee within 24 hours of providing bank details, you will be deemed to be in default of our fees, our fees will remain payable and your access to the site will be suspended.  Interest charged at 4% above the Bank of England’s base rate will be levied on outstanding amounts per UK legislation.</br></br>`,
        `All fees will be charged in pounds sterling (£) (GBP).</br></br>`
    ]
},
{
    id: "b14",
    question: "YOUR PRIVACY AND PERSONAL INFORMATION",
    answer: [
        `Your privacy and personal information are important to us. Our privacy policy explains how and for what purpose we handle your personal information.  It will tell you more on how to contact supervisory authorities if you have any queries or complaints about your personal information.</br></br>`,
        `Our privacy policy is available <a href="http://watchshuffle.com/privacy" target="_blank">here</a>.</br></br>`,
        `Payment information you supply to Stripe is not accessible by us and we do not exercise any control over it and it is subject to Stripe’s own privacy policy and terms and conditions available on their website.</br></br>`
    ]
},
{
    id: "b15",
    question: "CANCELLING THIS CONTRACT",
    answer: [
        `Consumers’ right to cancel: </br></br>`,
        `You may cancel your contract between you and us for the provision of online auction services that we provide. However, you cannot cancel, as a buyer, your contract to buy an auction lot following successfully bidding in and winning an auction. You cannot cancel your obligation to pay our fees.  You acknowledge and agree that when you act as a buyer on our site, Watch Shuffle completes its provision of service to you when you win an auction.  You acknowledge immediate provision of our service to you when you win an auction on our site. Given the service has been provided, you waive any statutory entitlement you may have to cancel contracts as a consumer.</br></br>`,
        `Cancelling your account</br>`,
        `Your account with Watch Shuffle can be cancelled at any time by informing us of your decision.</br></br>`,
        `Sellers who have active listings (coming soon lots of active auction lots) or buyers who have submitted bids on an auction that is live, cannot cancel their account with Watch Shuffle until conclusion of the auctions.</br></br>`,
    ]
},
{
    id: "b16",
    question: "SUBMITTING INFORMATION TO THE SITE",
    answer: [
        `Watch Shuffle makes every attempt to make our site secure.  Information that you submit, however, may be accessed by hostile entities (“hackers”) if our systems are compromised and so we cannot guarantee the security of any information that you supply to us and we cannot guarantee that it will remain confidential.</br></br>`,
        `Any content you upload to our site or send by other means must be related to the business of our site.  Any illegal or offensive content is prohibited to be shared with us.</br></br>`,
        `Breaching our code on the propriety of content means you will be liable to us and indemnify us for breach of this warranty and you will be responsible for any loss or damage we suffer as a result of your breach of the warranty you provide on content.</br></br>`,
        `The content you provide us will always be considered to be non-confidential and non proprietary.  Your content remains yours and you grant us a limited license to use your content including storage and copying as well as making it available to third parties as we see fit.</br></br>`,
        `All content submitted is deemed to be owned by you or in the case of ownership by a third party, we assume you have sought permission from the third party to submit their content.  This includes content that is copyrighted or is covered by other proprietary rights.</br></br>`,
        `Should any third party claim that content you have submitted is owned by them or is otherwise a violation of their intellectual property or their right to privacy, we retain the right to disclose your identity to them.</br></br>`,
        `Any reliance by third parties who are reliant on your content that is uploaded to our site do so at their own risk and we not be liable for any such reliance.</br></br>`,
    ]
},
{
    id: "b17",
    question: "OWNERSHIP, USE AND INTELLECTUAL PROPERTY RIGHTS",
    answer: [
        `We own the site and all intellectual property rights in it including but not limited to all content, photos, logos and trademarks.</br></br>`,
        `Accessing our site gives you no legal rights to the site other than to access the site as a buyer or seller or observer of our business. You agree not to interfere with anything on the site and in particular any security technology contained within the site.</br></br>`,
        `You are not allowed to data scrape, data mine or use other means to obtain information from our site without prior written permission from us.  This includes but is not limited to scraping data from APIs, web pages, databases or other sources of information on the site.  Data available on the site is for personal use only. Gathering and selling or data is strictly prohibited.</br></br>`,
    ]
},
{
    id: "b18",
    question: "TRADEMARKS",
    answer: [
        `“Watch Shuffle” and “WatchShuffle” are our trademarks and the only trademarks we own that are mentioned on the site. Other trademarks and trade names may also be used on the Site without us implying we own those trademarks. You cannot use our trademarks without our prior written permission.</br></br>`
    ]
},
{
    id: "b19",
    question: "ACCURACY OF INFORMATION AND AVAILABILITY OF THE SITE",
    answer: [
        `We try our very best to make the site error and bug free and fully up to date and fully accessible at all times. However, we cannot guarantee that the site will be available for any use at all and any reliance you place on access to the site or the information or facilities contained in it or through it is at your own risk.</br></br>`,
        `The site may be suspended or terminated from operation at any point as needed by us.</br></br>`
    ]
},
{
    id: "b20",
    question: "HYPERLINKS AND THIRD-PARTY SITES",
    answer: [
        `From time to time, our site may contain hyperlinks to other sites.  We have no control over other sites and accept no legal responsibility for anything contained in them.  We do no endorse other site, unless we have confirmed in writing that we do, and your use of third party sites is governed by the terms of use of those sites.</br></br>`,
        `You are welcome to create links to our site from other sites or apps but only if all of the following terms apply:</br></br>`,
        `You do not create a frame or border around our site;</br></br>`,
        `You do not imply that we endorse any other site or their products or services or content; or</br></br>`,
        `You do not display our trademarks without our written permission.</br></br>`,
        `We may ask you to immediately remove any links to our website and you are required to immediately comply with any such request, whether written or communicated by any other means.</br></br>`
    ]
},
{
    id: "b21",
    question: "LIMIT ON OUR RESPONSIBILITY TO YOU",
    answer: [
        `Limit on our responsibility to you if you are a consumer</br></br>`,
        `If you are a consumer, your rights to protection against and compensation for death or personal injury arising from using our site are protected in law and your personal information is to be protected as required by law.  Apart from these exceptions, we are not top be held legally responsible for any of the following:</br></br>`,
        `losses that: `,
        `could not be foreseen by you or us when these terms were formed; or </br></br>`,
        `that were not caused by a breach of these terms by us; or </br></br>`,
        `business losses; and </br></br>`,
        `losses to non-consumers</br></br>`,
        `Limit on our responsibility to you if you are not a consumer </br></br>`,
        `If you are not a consumer, our liability is set out as follows:  </br></br>`,
        `The total liability shall be limited to the amount of money you have paid us or are due to pay us for the services we have entered into a contract for</br></br>`,
        `We are not liable for any consequential losses you may incur, including indirect or special losses. </br></br>`,
        `We carry no liability for any of the following, whether direct or indirect:</br></br>`,
        `loss of profit;</br></br>`,
        `loss of or corruption of your data; </br></br>`,
        `loss of use; </br></br>`,
        `loss of opportunity</br></br>`,
        `harm to reputation or loss of goodw</br></br>`,
        `Notwithstanding any obligations we have per these terms, we will not have our liability limited in any way in respect of the following:  </br></br>`,
        `Negligence leading to death or personal injury; </br></br>`,
        `fraud or fraudulent misrepresentation; or</br></br>`,
        `any other losses which cannot be excluded or limited by applicable law.</br></br>`,
        `Watch Shuffle shall not be responsible for the obligations that buyer and seller enter into when contracting with each other.  Watch Shuffle gives no warranty or representation to trade or consumer users of the site about the description, quality, performance or authenticity of the auction lot. </br></br>`,
        `Whilst we have inbuilt systems that notify users of the site if they have been outbid, this may not happen in real time.  The functioning of our site is subject to delays and interruptions which may be beyond our control and Watch Shuffle shall carry no liability for any such delays, interruptions or difficulties in accessing our site. </br></br>`,
        `If you would like more information on an auction lot, you may request a pre purchase inspection report.  The following terms apply and any user of such reports agree to be bound by these terms:  </br></br>`,
        `Reports are limited to visual inspections and some defects may remain hidden by visual inspection.  The report does not provide an exhaustive condition report of the auction lot and not all features of the auction lot can be checked or reported on.</br></br>`,
        `Any report does not provide endorsement or discouragement from purchasing an auction lot. A report does not provide any substitute to obtaining professional advice before committing to bid on an auction lot. All bidding should take place after the bidder is satisfied they have adequate information about the auction lot to place a legally binding bid.  Potential viewers are also welcome to request to view or inspect the auction lot before bidding. Watch Shuffle does not guarantee such requests will result in a viewing or inspection time being made available. </br></br>`,
        `Pre purchase reports may require the removal of the case back of an auction lot for the purposes of inspection and photography of the watch movement of an auction lot.  This process is inherently risky and may result in damage to the watch or its internal components.  Sellers are free to disallow such caseback removal and acknowledge this may affect the salability of their submitted auction lot.  Where sellers do permit the removal of casebacks for pre purchase reports, they will do so in writing and will not hold Watch Shuffle or its watch makers liable for any damage or loss of water resistance that might ensue from caseback removal.</br></br>`,
        `Any use of a pre purchase report provided by Watch Shuffle is at the user’s risk. </br></br>`,
    ]
},
{
    id: "b22",
    question: "EVENTS BEYOND OUR CONTROL",
    answer: [
        `If any of these terms are breached as a result of events or circumstances beyond our control including lockdowns, natural disasters and other unforeseeable eventualities, we carry no liability to you.</br></br>`
    ]
},
{
    id: "b23",
    question: "RIGHTS OF THIRD PARTIES",
    answer: [
        `Only those parties that are party to these terms have any right to enforce any of these terms.</br></br>`
    ]
},
{
    id: "b24",
    question: "VARIATION",
    answer: [
        `There is no right to make changes to these terms unless we agree to changes in writing.  We may need to change these terms from time to time and so you should check the terms on our site from time to time.  We will also notify our site users by email of any changes to our terms and conditions.  If our terms change and you no longer wish to be bound by them, you may terminate your account with us as described earlier in these terms.</br></br>`
    ]
},
{
    id: "b25",
    question: "UNENFORCEABLE PROVISIONS",
    answer: [
        `Provisions in these terms which are found to be unenforceable do no affect other provisions in the terms, all of which will remain valid and unaffected.</br></br>`
    ]
},
{
    id: "b26",
    question: "JURISDICTION AND APPLICABLE LAW",
    answer: [
        `If you are a business user, the courts of England and Wales will have exclusive jurisdiction in relation to these Terms and English law will apply to these Terms.</br></br>`,
        `If you are a consumer user, the courts of the United Kingdom will have non-exclusive jurisdiction in relation to these Terms and English law will apply to these Terms.</br></br>`
    ]
},
{
    id: "b27",
    question: "END TO END MANAGED SERVICE - ADDITIONAL TERMS AND CONDITIONS",
    answer: [
        `INSURANCE, CARRIAGE AND LIABILITY</br></br>`,
        `For inward shipping from you to Watch Shuffle, your £150 listing fee covers carriage by our specialist jewellery courier who covers most of the UK.  If your postcode is not covered by our courier, we can advise of alternative means to shipping your watch to us.  You are responsible for insuring your Lot until your Lot is received by Watch Shuffle personnel at our Mayfair address and we have confirmed receipt to you together with an inventory of the received items and their condition. Watch Shuffle becomes responsible for your Auction Lot only when it is received by us and in our possession. Watch Shuffle will not be liable to the Seller for any loss or damage arising out of or in connection with your Auction Lot that may occur prior to this point.</br></br>`,
        `Whilst in our care, your auction lot will be insured by us to the reserve price we have agreed and in cases where there is no reserve, to an otherwise agreed value which will be agreed prior to use accepting your auction lot for auction.  You agree that if your auction lot is lost or damaged, our liability is limited to the value or your reserve or agreed value.  You agree that payout to you for lost or damaged items will be subject to payout to us by our insurers.  We will pay you with 2 working days of us being paid out by our policy.</br></br>`,
        `Your auction lot may be held at our premises or at an off site secure facility.</br></br>`,
        `If your auction lot sells successfully at auction, we will liaise with the buyer to arrange payment directly to you from the buyer.  Once you have received payment from the buyer, we will require you to sign an online transfer of title document via the online platform docusign which will list the particulars of the parties to the sale, the description of the auction lot and that you have been paid.  We require you to do this within 24 hours of being paid so that we can arrange onward handing over of the auction lot to the buyer.  We will not release the auction lot until you have signed this document and failure or delay in signing this document beyond 24 hours after payment being sent and the document being sent to you will result in a fee of £600 or the value of auction fees being levied on your payment card.  This is to compensate the buyer in the delay in releasing the auction lot that they have won and paid for.</br></br>`,
        `Any issues with receiving payment must be notified to Watch Shuffle in writing at <a href="mailto:hello@watchshuffle.com">hello@watchshuffle.com</a> with your username and auction listing number.</br></br>`,
        `Onward shipping to the buyer of the auction lot ,once the transfer of title document has been signed and our fees paid, is payable by buyers and this will be arranged by us at cost if the buyer opts not to collect their auction lot from our office.</br></br>`,
        `In cases where an auction lot fails to sell, sellers have the option of relisting the auction lot with a an agreed reduced reserve.  IN such a case, the auction lot remains in our possession and care and remains fully insured and no further listing fees are due from seller to us.  Should you choose not to relist the Auction Lot, you can opt for fully insured shipping back to you via our jewellery courier or collect from our Mayfair office by prior agreement. Return fully insured shipping is charged at £150. If you are collecting or sending a courier or someone else to collect, we will only release to a named courier or person that you have confirmed with us in writing.  Proof of identity will be required before releasing your auction lot to any third party you have specified and provided details for.</br></br>`,
        `Auction lots must be collected by buyers within 7 working days from the sale date from our Mayfair office or buyers need to request or arrange return shipping within this window.  If no sale has taken place, sellers need to collect their auction lot or arrange or request return shipping within 7 calendar days of the auction end.  Watch Shuffle will release auction lots only once all fees due to Watch Shuffle have been paid. A storage fee of £10 per day will be charged to buyer or seller accordingly for auction lots remaining in our possession seven days after the end of an auction.</br></br>`
    ]
},
];