import styles from "./Item.module.scss";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";

export interface PrivacyAndCookies {
    id: string;
    question: string;
    answer: string[];
}

export interface PrivacyProps {
    index: number;
    item: PrivacyAndCookies;
}

const PrivacyAndCookiesItem = ({ index, item }: PrivacyProps) => {
    const [showAnswer, setShowAnswer] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={styles.root}>
            <div className={styles.contentContainer}>
                <div className={styles.questionAndAnswerContainer}>
                    <div
                        className={styles.questionContainer}
                        onClick={() => setShowAnswer((prev) => !prev)}
                    >
                        <Typography className={styles.questionText}>{`${
                            index + 1
                        }. ${item.question}`}</Typography>
                    </div>

                    {showAnswer
                        ? item.answer.map((answer) => (
                              <Typography
                                  className={styles.answerText}
                                  dangerouslySetInnerHTML={{ __html: answer }}
                              />
                          ))
                        : null}
                </div>
                {item.answer.length ? (
                    <div className={styles.iconContainer}>
                        <IconButton
                            className={styles.iconButton}
                            aria-label="Show/Hide Answer"
                            onClick={() => setShowAnswer((prev) => !prev)}
                        >
                            {showAnswer ? (
                                <ExpandLessIcon />
                            ) : (
                                <ExpandMoreIcon />
                            )}
                        </IconButton>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default PrivacyAndCookiesItem;
