import React from "react";
import styles from "./Footer.module.scss";
import { Typography } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { ReactComponent as InstagramIcon } from "../../assets/instagram.svg";
import { ReactComponent as TwitterIcon } from "../../assets/twitter.svg";
import { NavLink } from "react-router-dom";
import CopyrightFooter from "./CopyrightFooter";

export interface FooterProps {
    hideInfoLinks?: boolean;
}

const Footer = ({ hideInfoLinks }: FooterProps) => {
    const linksEnabled = true;

    const getNavLink = (path: string, label: string) => {
        if (linksEnabled) {
            return <NavLink to={path}>{label}</NavLink>;
        } else {
            return (
                <Typography className={styles.disabledLink}>{label}</Typography>
            );
        }
    };

    // const getTCNavLink = (path: string, label: string) => {
    //     if (linksEnabled) {
    //         return <NavLink to={path}>{label}</NavLink>;
    //     } else {
    //         return (
    //             <Typography className={styles.disabledTCLink}>
    //                 {label}
    //             </Typography>
    //         );
    //     }
    // };

    return (
        <div className={styles.root}>
            {/* <div className={styles.stayInTouchContainer1}>
                <div className={styles.stayInTouchContainer2}>
                    <Typography className={styles.header}>
                        Contact
                    </Typography>

                    <div className={styles.iconsContainer}>
                        <a href="mailto:hello@watchshuffle.com"><EmailOutlinedIcon className={styles.emailIcon}/></a>
                        <a href="https://twitter.com/watchshuffle" target="_blank" rel="noreferrer noopener"><TwitterIcon className={styles.twitterIcon}/></a>
                        <a href="tel:03003023135"><LocalPhoneIcon className={styles.phoneIcon}/></a>
                        <a href="https://www.instagram.com/watchshuffle/" target="_blank" rel="noreferrer noopener"><InstagramIcon className={styles.instagramIcon}/></a>
                    </div>
                </div>
            </div> */}

            {!hideInfoLinks && (
                <div className={styles.infoLinksContainer1}>
                    <div className={styles.infoLinksContainer2}>
                        <div
                            style={{ gridColumn: 1 }}
                            className={styles.infoLinksContainer3}
                        >
                            <div className={styles.infoLinksContainer4}>
                                <Typography className={styles.header1}>
                                    BUY
                                </Typography>
                                {getNavLink("/buy", "Watch Showroom")}
                                <Typography className={styles.header2}>
                                    SELL
                                </Typography>
                                {getNavLink("/sell", "Sell with us")}
                            </div>
                        </div>

                        <div
                            style={{ gridColumn: 2 }}
                            className={styles.infoLinksContainer3}
                        />

                        <div
                            style={{ gridColumn: 3 }}
                            className={styles.infoLinksContainer3}
                        >
                            <div className={styles.infoLinksContainer4}>
                                <Typography className={styles.header1}>
                                    ABOUT
                                </Typography>
                                {getNavLink("/info", "About Us")}
                                {getNavLink("/faq", "FAQ")}
                                {getNavLink("/terms&conditions", "T&C's")}
                                {getNavLink("/privacy", "Privacy Policy")}
                                {getNavLink("/cookies", "Cookie Policy")}
                            </div>
                        </div>

                        <div
                            style={{ gridColumn: 4 }}
                            className={styles.infoLinksContainer3}
                        />
                        <div
                            style={{ gridColumn: 5, paddingTop: 30 }}
                            className={styles.infoLinksContainer3}
                        >
                            <div className={styles.infoLinksContainer4}>
                                <a href="mailto:hello@watchshuffle.com">
                                    <EmailOutlinedIcon
                                        className={styles.emailIcon}
                                    />
                                </a>
                                <a
                                    href="https://twitter.com/watchshuffle"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <TwitterIcon
                                        className={styles.twitterIcon}
                                    />
                                </a>
                                <a href="tel:03003023135">
                                    <LocalPhoneIcon
                                        className={styles.phoneIcon}
                                    />
                                </a>
                                <a
                                    href="https://www.instagram.com/watchshuffle/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <InstagramIcon
                                        className={styles.instagramIcon}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* <div className={styles.copyrightContainer1}>
                <div className={styles.copyrightContainer2}>
                    <div className={styles.linksContainer}>
                        {getTCNavLink("/terms&conditions", "CONDITIONS OF SALE")}
                        <div className={styles.navSpacer} />
                        {getTCNavLink("/terms&conditions", "TERMS OF USE")}
                        <div className={styles.navSpacer} />
                        {getTCNavLink("/privacy", "PRIVACY POLICY")}
                        <div className={styles.navSpacer} />
                        {getTCNavLink("/cookies", "COOKIE POLICY")}
                    </div>

                    <Typography className={styles.copyrightText}>
                        Copyright Watch Shuffle. All rights reserved.
                    </Typography>
                </div>
            </div> */}

            <CopyrightFooter />
        </div>
    );
};

export default Footer;