import { PrivacyAndCookies } from "./Item";

export const privacyData: PrivacyAndCookies[] = [
    {
        id: "b1",
        question: "Information Collection:",
        answer: [
            `We collect and process various types of data, including:`,
            `<ol>
                <li>Information provided through forms on <a href='https://watchshuffle.com/'>www.watchshuffle.com</a> when you register, subscribe, post content, or request services. This may also include data provided during competitions, promotions, or website issue reporting.</li>
                <li>Records of correspondence when you reach out to us.</li>
                <li>Survey responses for research purposes (optional to participate).</li>
                <li>Details of your site visits, including traffic and communication data, for our records or billing purposes.</li>
            </ol>`
        ],
    },
    {
        id: "b2",
        question: "IP Addresses:",
        answer: [
            "We may collect information such as IP addresses, operating systems, and browser types. This data is used for system administration and aggregated for advertiser reporting and remains anonymous.",
        ],
    },
    {
        id: "b3",
        question: "Data Storage:",
        answer: [
            "Your data may be transferred, stored, or processed outside the European Economic Area (EEA) by our suppliers. By providing your data, you consent to this transfer. We implement stringent security measures to protect your information on secure servers.",
            "We take all reasonable steps to ensure your data is handled securely in accordance with this privacy policy. All financial transactions undergo encryption via SSL technology. If you have been provided or chosen a password for site access, please keep it confidential and avoid sharing it. While we strive to protect your data, internet transmission is not entirely secure. Once we receive your information, we employ stringent procedures and security features to prevent unauthorised access."
        ],
    },
    {
        id: "b4",
        question: "Information Usage:",
        answer: [
            `We use your data for:`,
            `<ol>
                <li>Optimising site content presentation.</li>
                <li>Providing requested information, products, or services, and communicating updates or offers.</li>
                <li>Fulfilling contractual obligations.</li>
                <li>Enabling your participation in interactive features.</li>
                <li>Notifying you about service changes.</li>
                <li>Conducting market research and running promotions or events.</li>
            </ol>`,
            "We may also share your data with selected third parties for marketing purposes, unless you specify otherwise."
        ],
    },
    {
        id: "b5",
        question: "Watch Loss Register:",
        answer: [
            `If a watch is stolen or missing, we may disclose your personal details to entities such as the Art Loss Register, law enforcement, or the police to aid in recovery efforts. Lost or stolen watches can be tracked through the global Watch Register for potential retrieval. Further details about the Art Loss Register can be found here.`,
            "We'll disclose personal information to law enforcement or other authorities if required by law. The parties outlined in this privacy policy may receive your personal information for the stated purposes.",
            "We may share your personal details to connect you with a watch's buyer or seller, facilitating transactions or addressing related disputes.",
            "To validate user authenticity and prevent fraud, we may share your personal data, including email and postal addresses, with agencies conducting background checks for fraud risk assessments. We prioritize sharing only necessary personal information.",
        ],
    },
    {
        id: "b6",
        question: "Communications Monitoring:",
        answer: [
            `We may monitor and use your communications with us for training, quality improvement, and security purposes.`,
        ],
    },
    {
        id: "b7",
        question: "Your Rights:",
        answer: [
            "You have the right to opt-out of data processing for marketing. You can also request access to your information under the Data Protection Act 1998."
        ],
    },
    {
        id: "b8",
        question: "Changes to Policy:",
        answer: [
            "Any future changes to our privacy policy will be updated on our site and communicated via email where appropriate."
        ],
    },
    {
        id: "b9",
        question: "Contact:",
        answer: [
            `For inquiries or requests related to this policy, contact us at <a href="mailto:support@watchshuffle.com">support@watchshuffle.com</a>.`
        ],
    }
];

export const cookiesData: PrivacyAndCookies[] = [
    {
        id: "b1",
        question: "Cookies Privacy Policy:",
        answer: [
            `We may use cookies to enhance your browsing experience and gather general internet usage information. By continuing to use our site, you agree to cookie usage. These cookies help improve site functionality and offer personalised services. You can manage cookie settings in your browser, but blocking essential cookies may limit site accessibility. Our advertisers may also employ cookies beyond our control.`,
        ],
    },
    {
        id: "b2",
        question: "Third Party Cookies:",
        answer: [
            `Plausible is employed on this website to gain insights into your usage patterns, aiding us in enhancing your experience. These cookies might monitor aspects like your site duration and visited pages, enabling us to generate compelling content continually.`,
            "For card payments, we utilise Stripe, which also installs cookies on your device. These cookies serve Stripe's payment processing and fraud detection functionalities.",
        ],
    },
    {
        id: "b3",
        question: "Cookie Settings:",
        answer: [
            "<b>Essential Cookies:</b> Always activated. These fundamental cookies are crucial for core functions like security, network management, and accessibility. They're implemented by us, not third parties. You cannot deactivate these, and your consent isn't required for their use.",
            "<b>Functional Cookies:</b> These cookies enhance website functionality and personalisation, such as remembering your account login details. While these can be disabled, it might impact website performance and some features might not work correctly.",
            "<b>Analytical/Performance Cookies:</b> We aim to utilise analytics cookies, like Google Analytics and other performance cookies, to enhance our website by gathering and presenting usage information. These cookies are provided by third-party service providers, as outlined in our Cookie Policy, and do not directly identify individuals.",
        ],
    },
];
